import * as React from "react"
import Header from "../components/Header"
import Services from "../components/Services/Services"
import Tech from "../components/Tech"
import Footer from "../components/Footer"
import Contact from "../components/Contact"
import "../index.css"
import { Helmet } from "react-helmet"
import { CssBaseline } from "@material-ui/core"

const IndexPage = () => {
  return (
    <main>
      <CssBaseline />
      <Helmet title="Cofiato Inc." defer={false} />

      <Header />
      <Services />
      <Tech />

      <Contact />
    </main>
  )
}

export default IndexPage
