import { Stack, styled } from "@material-ui/core"

const ServiceWrapper = styled(Stack)`
  scroll-snap-align: start;
  position: relative;
  min-height: 100vh;
  transform: translateY(-1px);
  overflow: hidden;
  z-index: 8;
  /* background: #edf3ff; */

  .services {
    background: white;
    padding: 40px 45px 65px;
    /* border: 1px solid rgba(150, 150, 150, 0.1); */
    /* box-shadow: -10px -10px 20px rgba(0, 0, 0, 0.15); */
    z-index: 5;
    margin: auto;
    padding-left: 10%;
    padding-right: 10%;
    @media (max-width: 1440px) {
      padding-left: 0%;
      padding-right: 0%;
      padding: 25px 90px 35px;
      max-width: 1200px;
      margin: 4%;
    }
    @media (max-width: 1440px) {
      padding-left: 0%;
      padding-right: 0%;
      padding: 25px 20px 35px;
      /* max-width: 1200px; */
      margin: 4%;
    }
  }
  .techRow {
    width: 100%;
    margin-top: 95px;
    @media (max-width: 1366px) {
      margin-top: 35px;
    }
  }
  .underlay {
    width: 100%;
    height: 70%;
    background: #dae6ff;
    /* background: #dae6ff; */
    position: absolute;
    z-index: 4;
    top: 20%;
  }

  .underlay {
    width: 100%;
    height: 20%;

    background: #dae6ff;
    /* background: #dae6ff; */
    position: absolute;
    z-index: 4;
    top: 80%;
  }

  & .title {
    font-size: 24px;
    text-align: center;
    font-family: "Montserrat";
    font-weight: 700;
    letter-spacing: 0px;
    color: #333;
    @media (max-width: 1366px) {
      font-size: 20px;
      padding: 20px 0 10px;
    }
  }
`

export default ServiceWrapper
