import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { Stack, Container, Grid } from "@material-ui/core"
import pnd from "../images/projects/PND.png"
import motivata from "../images/projects/motivata.png"
import tcl from "../images/projects/tcl.png"
import davinci from "../images/projects/davinci.png"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { StaticImage } from "gatsby-plugin-image"

const Tech = () => {
  const matches = useMediaQuery("(max-width:425px)")
  function shuffleArray(array: any[]) {
    let curId = array.length
    // There remain elements to shuffle
    while (0 !== curId) {
      // Pick a remaining element
      let randId = Math.floor(Math.random() * curId)
      curId -= 1
      // Swap it with the current element.
      let tmp = array[curId]
      array[curId] = array[randId]
      array[randId] = tmp
    }
    return array
  }
  const settings = {
    dots: false,
    slidesToShow: matches ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    infinite: true,
  }

  // const secondSettings = {
  //   dots: false,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 3000,
  //   autoplaySpeed: 3000,
  //   cssEase: "linear",
  //   infinite: true,
  // }
  const a = [pnd, motivata, tcl, davinci]

  return (
    <Wrapper>
      <div className="services">
        <br />
        <br />
        <br />
        <div className={"title"}>Our Recent Projects</div>
        <br />
        <br />
        <br />
        <br />

        <Slider {...settings}>
          {shuffleArray(a).map((img) => (
            <div>
              <div
                style={{
                  margin: "0 10px",
                  height: "350px",
                  // background: "pink",
                  width: "calc(100% - 20px)",
                  background: `url(${img})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  /* min-height: 100vh; */
  margin-bottom: 3em;
  @media (max-width: 720px) {
    display: none;
  }

  overflow: hidden;
  background: #edf3ff;
  background: linear-gradient(180deg, #dae6ff 0%, #dae6ff 50%, white 100%);
  transform: translateY(-1px);
  text-align: center;
  padding-bottom: 35px;

  .title {
    font-size: 28px;
    text-align: center;
    font-family: "Montserrat";
    font-weight: 700;
    letter-spacing: 0.65px;
    color: #333;
  }
`
export default Tech
