import React from "react"
import { Stack, Container, Grid, useMediaQuery } from "@material-ui/core"
import techRow from "../../images/techrow.png"
import techRowMobile from "../../images/bubbles-mobile.png"
import { useStaticQuery, graphql } from "gatsby"
import ServiceGrid from "./ServiceGrid"
import ServiceWrapper from "./ServiceWrapper"

const Services = () => {
  const {
    allMarkdownRemark: { edges: services },
  } = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { type: { eq: "service" } } }) {
        edges {
          node {
            id
            excerpt
            frontmatter {
              title
              icon
            }
          }
        }
      }
    }
  `)
  const matches = useMediaQuery("(max-width:600px)")
  return (
    <ServiceWrapper alignItems={"center"} id={"services"}>
      <Container className="services" maxWidth={"xl"}>
        <div className="title">We Ply Our Trade In</div>
        <ServiceGrid container spacing={3}>
          {services.map(({ node: service }: ServiceNode) => (
            <Grid item lg={4} md={4} xs={12} key={service.id}>
              <Stack spacing={2} justifyContent="center">
                <div>
                  <img src={service.frontmatter.icon} />
                </div>

                <div className={"heading"}>{service.frontmatter.title}</div>
                <div className={"subheading"}>{service.excerpt}</div>
              </Stack>
            </Grid>
          ))}
        </ServiceGrid>

        <img className={"techRow"} src={matches ? techRowMobile : techRow} />
      </Container>
    </ServiceWrapper>
  )
}

type Service = {
  id: string
  excerpt: string
  frontmatter: ServiceFrontMatter
}

type ServiceFrontMatter = {
  title: string
  icon: string
}

type ServiceNode = {
  node: Service
}

export default Services
