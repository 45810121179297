import React from "react"
import styled from "styled-components"
import vector from "../images/vector1.svg"
import developer from "../images/developer.svg"
import bubbles from "../images/bubbles.png"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import { Button, Stack, ButtonGroup } from "@material-ui/core"
import { FaArrowRight } from "react-icons/fa"
import { useStaticQuery, graphql } from "gatsby"
import logo from "../images/logo.svg"
import { Link } from "gatsby"

const Header = () => {
  const {
    headline: { title, subtitle },
    settings,
  } = useStaticQuery(graphql`
    {
      headline: configJson(type: { eq: "headline" }) {
        title
        subtitle
      }
      settings: configJson(type: { eq: "settings" }) {
        hiring
      }
    }
  `)

  return (
    <Wrapper>
      {settings.hiring && <Hiring to="/careers">🎉 We are Hiring!</Hiring>}

      <Container className="container" maxWidth={"xl"}>
        <div>
          <Link to="/">
            <img height={39} src={logo} width={105} />
          </Link>
        </div>
        <Grid
          container
          style={{ height: "100%", zIndex: 10, position: "relative" }}
          direction="row"
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          <Grid item xs={12} md={6}>
            <Content>
              <div className="title">
                <div>{title}</div>
                <div className="title2">{subtitle}</div>
              </div>
              <div className="subtitle">
                Give your ideas wings with <span> elegant designs </span>
                <br />
                and <span>cutting edge</span> technologies
              </div>
              <a style={{ textDecoration: "none" }} href={"#services"}>
                <ActionButton size="large">
                  <Button className={"text"}>Learn More</Button>
                  <Button className={"arrow"} disableRipple disableFocusRipple>
                    <FaArrowRight />
                  </Button>
                </ActionButton>
              </a>
            </Content>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              alignItems={"flex-end"}
              style={{ position: "relative", flex: 1 }}
            >
              <Bubbles src={bubbles} />
              <img className={"developer"} src={developer} />
            </Stack>
          </Grid>
        </Grid>

        {/* <imgsrc={vector} /> */}
        <HeaderGraphic className={"vector"} />
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  background: linear-gradient(180deg, #dae6ff 0%, #edf3ff 100%, #edf3ff 100%);
  scroll-snap-align: start;
  position: relative;
  height: 100vh;
  overflow: hidden;

  .container {
    padding: 50px 0 20px;
    height: 85%;
  }

  @media (max-width: 1440px) {
    .container {
      padding: 45px 75px;
      height: 85%;
    }
  }

  @media (max-width: 1440px) {
    .container {
      padding: 45px 25px;
      height: 85%;
    }
  }

  .vector {
    position: absolute;

    left: 0;
    bottom: 0px;
    z-index: 1;
    width: 100%;
    height: auto;
  }

  .developer {
    width: 80%;
    @media (max-width: 1366px) {
      transform: translateY(40px);
      width: 75%;
    }
    @media (max-width: 425px) {
      width: 100%;
      /* top: -42%; */
      transform: translateY(100px);
    }
  }
`

const Content = styled(Stack)`
  flex: 1;

  & > .title {
    font-size: 4.5rem;
    font-family: "Ubuntu";
    font-weight: 600;
    line-height: 1.2em;
    @media (max-width: 1440px) {
      font-size: 3.5rem;
    }

    @media (max-width: 425px) {
      font-size: 2rem;
    }

    .title2 {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .subtitle {
    color: #db5892;
    font-family: "Poppins";
    font-size: 1.5rem;
    line-height: 1.5em;
    margin-top: 30px;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

    & span {
      font-weight: 600;
    }

    @media (max-width: 1440px) {
      margin-top: 10px;
    }
    @media (max-width: 425px) {
      margin-top: 10px;
      font-size: 1.2rem;
    }
  }
`

const HeaderGraphic: React.FC = (props) => (
  <svg
    viewBox="0 0 678 179"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M678 110.449C673.6 -127.196 218.167 77.4425 -1 179H678V110.449Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="338.5"
        y1="0"
        x2="338.5"
        y2="179"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F2C775" />
        <stop offset="1" stop-color="#DB5693" />
      </linearGradient>
    </defs>
  </svg>
)

const Bubbles = styled.img`
  width: 80%;
  position: absolute;
  top: -69%;
  transition: all 0.3s ease-in-out;
  animation-iteration-count: infinite;
  animation-name: fly;
  animation-delay: 0.1s;
  animation-duration: 4.5s;

  @media (max-width: 1440px) {
    width: 75%;
    top: -62%;
  }

  @media (max-width: 425px) {
    width: 100%;
    top: -12%;
  }

  @keyframes fly {
    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(1rem);
    }
  }
`

const ActionButton = styled(ButtonGroup)`
  margin-top: 45px;
  text-decoration: none;
  @media (max-width: 1366px) {
    margin-top: 35px;
  }
  button,
  button:hover {
    background: #edb07b;
    border-radius: 0;
    border: none;
    color: white;
    font-family: "Montserrat";
    font-weight: 600;
    text-decoration: none;

    &.arrow {
      background: #f4d0b0;
    }

    &.text {
      padding-left: 45px;
      padding-right: 45px;
      letter-spacing: 1px;
      text-decoration: none;
    }

    &.text:hover {
      background: #e79852;
    }
  }
`

const Hiring = styled(Link)`
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  color: black;
  text-align: center;
  padding: 8px;
  font-family: "Montserrat";
  text-transform: uppercase;
  transition: 0.3s ease;
  font-size: 11px;
  letter-spacing: 1px;
  position: absolute;
  font-weight: 600;
  width: 100%;
  display: block;

  animation: load 3s;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  @keyframes load {
    0% {
      transform: translateY(-40px);
      display: hidden;
    }

    85% {
      transform: translateY(-40px);
      display: block;
    }

    100% {
      transform: translateY(0);
      display: block;
    }
  }
`

export default Header
